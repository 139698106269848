import Carcas from "../../../components/containers/carcas";
import backend from "../../../../scripts/backend";
import API from "../../../../constants/api";
import useGoBack from "../../../../scripts/hooks/use.go.back";
import { useParams } from "react-router-dom";
import useGlobal from "../../../../store";
import { useEffect } from "react";
import ServerGenerationForm from "../../../components/forms/configurator/server.generation.form";

const ServerGenerationEdit = () => {

  const params = useParams();
  const { id } = params;
  const goBack = useGoBack();

  const [ globalState, globalActions ] = useGlobal();
  const { initialData } = globalState;
  const { changeStates } = globalActions;

  useEffect(() => {

    !initialData?.id && goBack();
    return () => { changeStates({ initialData: {} }) }

  }, []);

  const handleEdit = ( form ) => {
    
    backend.patch( API.configurator.server.generation.edit( id ), form, goBack );
  
  }

  const handleRemove = () => {
    
    if ( !window.confirm('Точно хотите удалить?') ) return;
    backend.remove( API.configurator.server.generation.remove( id ), goBack, false, 'delete' );
  
  }

  return (

    <Carcas>

      <ServerGenerationForm
      
        initialData   = { initialData }
        handleConfirm = { handleEdit }
        handleRemove  = { handleRemove }
        handleCancel  = { goBack }
        
      />

    </Carcas>

  );

}

export default ServerGenerationEdit;