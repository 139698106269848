import Carcas from "../../components/containers/carcas";
import backend from "../../../scripts/backend";
import API from "../../../constants/api";
import useGoBack from "../../../scripts/hooks/use.go.back";
import NewsForm from "../../components/forms/news";

const NewsAdd = () => {

  const goBack = useGoBack();

  const handleAdd = ( form ) => {
    
    backend.add( API.news.add, form, goBack );
  
  }

  return (

    <Carcas>

      <NewsForm
      
        handleConfirm = { handleAdd }
        handleCancel  = { goBack } 
        
      />

    </Carcas>

  );

}

export default NewsAdd;