import React, { Fragment } from 'react';
import "./textfield.scss";
import cssIf from '../../../../scripts/helpers/class.add.if';

const Textfield = ( props ) => {
  
  const { 

    value, 
    set = () => {}, 
    title = "", 
    type = "text", 
    placeholder = "", 
    className = "",
    inputClassName = "", 
    refDOM = false, 
    onBlur = () => {}, 
    onKeyUp = () => {},
    icon = <Fragment />,
    editable = true,
    isLink = false

  } = props;

  return (

    <div className = {`textfield ${ className }`}>

      { title !== "" && <div className="textfield__title">{ title }</div> }

      <div className = "relative">

        { !isLink ?
          
            <input

              type = { type }
              value = { value }
              placeholder = { placeholder }
              className = {` ${ inputClassName } ${ cssIf( !editable, 'non_editable_input' ) }`}
              onChange = { ( event ) => { set( event.target.value ) }}
              onBlur = { onBlur }
              onKeyUp = { onKeyUp }
              ref = { refDOM ? refDOM : null }

            />

            :

            <a 
            
              href = { value }
              target = "_blank"
              rel = "noreferrer"
              className = {` ${ inputClassName } ${ cssIf( !editable, 'non_editable_input' ) }`}
            
            >
              
              { value }
              
            </a>

        }

        { icon }

      </div>

    </div>

  );

}

export default Textfield;