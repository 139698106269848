import { Fragment, useEffect, useMemo } from "react";
import Textfield from "../../../../components/inputs/textfield";
import SelectFromBackend from "../../../../components/inputs/select.from.backend";
import useObjectState from "../../../../../scripts/hooks/use.object.state";
import ServerSlot from "../../../../components/cards/server.slot";
import SlotsContainer from "../../../../components/containers/slots.container";
import ActionButtons from "../../../inputs/action.buttons";
import useArrayOfObjects from "../../../../../scripts/hooks/use.array.of.objects";
import useIdFromState from "../../../../../scripts/hooks/use.id.from.state";
import allObjectsIsHaveFilledKey from "../../../../../scripts/validators/all.objects.is.have.filled.key";
import isNotEmpty from "../../../../../scripts/validators/is.not.empty";
import useReloadDummy from "../../../../../scripts/hooks/use.reload.dummy";
import Filefield from "../../../inputs/filefield";
import Textarea from "../../../inputs/textarea";

const ServerForm = ({ initialData, handleConfirm, handleCancel, handleRemove = false }) => {

  const [ form, setFormField ] = useObjectState( initialData );
  const [ Dummy, reloadDummy ] = useReloadDummy();

  useEffect(() => {

    let united = [];
    
    if ( !form?.slots?.length && !form?.multislots?.length ) { return; }

    if ( form?.slots?.length ) { 
      
      united = united.concat( form.slots ); 
    
    }
    
    if ( form?.multislots?.length ) {

      for ( const ms of form.multislots ) {

        united.push({

          isMultiSlot: true,
          slot_id: ms.multislot_id,
          slotId: ms.multislot_id,
          amount: ms.amount,
          on_back_panel: ms.on_back_panel,
          onBackPanel: ms.on_back_panel

        });

      }
      
    }

    setFormField( "slots", united );
    reloadDummy();

  }, [])

  const [ serverboxHeight,  setServerboxHeight  ] = useIdFromState( form?.serverbox_height_id,  id => setFormField("serverbox_height_id",  id ) );
  const [ serverGeneration, setServerGeneration ] = useIdFromState( form?.server_generation_id, id => setFormField("server_generation_id", id ) );

  const initialSlotData = {
      
    slot_id: null,
    amount: 1,
    on_back_panel: false

  }

  const slot = useArrayOfObjects( form?.slots, payload => setFormField( "slots", payload ), initialSlotData );

  const formIsValidated = useMemo(() => (
    
    isNotEmpty( form?.serverbox_height_id )
    &&
    isNotEmpty( form?.name )
    &&
    isNotEmpty( form?.price )
    &&
    isNotEmpty( form?.slots )
    &&
    allObjectsIsHaveFilledKey( form?.slots, 'slot_id' )

  ), [ form?.serverbox_height_id, form?.name, form?.price, slot._trigger ]);

  function transformFormForBackend( data ) {

    const slots = []
    const multislots = []

    for ( const slt of data?.slots ) {

      const s = {

        ...slt,
        on_back_panel: slt?.onBackPanel || slt?.on_back_panel

      }

      !s?.isMultiSlot 
        ? slots.push( s )
        : multislots.push({

            multislot_id: s.slot_id,
            amount: s.amount,
            onBackPanel: s.on_back_panel

          });

    }

    return {

      ...form,
      slots,
      multislots

    }

  }

  return (

    <Fragment>

      <h2>{ form?.id ? 'Редактировать' : 'Добавить' } сервер</h2>

      <br/>

      <Dummy />
      
      <SelectFromBackend

        title = 'Высота сервера:'
        source = 'configurator/serverHeight'
        selected = { serverboxHeight }
        select = { setServerboxHeight }

      />
      
      <SelectFromBackend

        title = 'Поколение сервера:'
        source = 'configurator/serverGeneration'
        selected = { serverGeneration }
        select = { setServerGeneration }

      />

      <Textfield

        title = "Название сервера:"
        value = { form.name }
        set = { n => setFormField("name", n ) }

      />

      <Textarea

        title = "Описание сервера:"
        value = { form.description }
        set = { n => setFormField("description", n ) }

      />

      <Textfield

        title = "Стоимость:"
        type = "number"
        value = { form.price }
        set = { n => setFormField("price", +n ) }

      />

      <Filefield
      
        title = "Изображение сервера:"
        value = { form.image }
        set = { n => setFormField("image", n ) }
        type = "image"
      
      />

      <Filefield
        
        title = "Инструкция:"
        value = { form.guide }
        set = { n => setFormField("guide", n ) }
        type = "document"
      
      />

      <Filefield
        
        title = "Сертификат:"
        value = { form.cert }
        set = { n => setFormField("cert", n ) }
        type = "document"
      
      />

      <SlotsContainer add = { slot.add }>

        { !form.slots?.length ? null : form.slots.map(( s, index ) => (

          <ServerSlot

            key = { index }
            slotId = { s.slotId }
            amount = { s.amount }
            onBackPanel = { s?.onBackPanel || s?.on_back_panel }

            set = { ( field, value ) => slot.edit( index, field, value ) }
            remove = { () => slot.remove( index ) }

          />

        ))}

      </SlotsContainer>

      {/* _________ united slots & multislots:
      <RenderJSON data = { debug } /> */}
      {/* _________ slots:
      <RenderJSON data = { form?.slots } />
      _________ multislots:
      <RenderJSON data = { form?.multislots } /> */}

      <ActionButtons

        confirm = { () => handleConfirm( transformFormForBackend( form ) ) }
        confirmDisabled = { !formIsValidated }

        remove = { handleRemove }
        cancel = { handleCancel }

      />

    </Fragment>

  );

}

export default ServerForm;