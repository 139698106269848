import Carcas from "../../components/containers/carcas";
import backend from "../../../scripts/backend";
import API from "../../../constants/api";
import useGoBack from "../../../scripts/hooks/use.go.back";
import { useParams } from "react-router-dom";
import useGlobal from "../../../store";
import { useEffect } from "react";
import NewsForm from "../../components/forms/news";

const NewsEdit = () => {

  const params = useParams();
  const { id } = params;
  const goBack = useGoBack();

  const [ globalState, globalActions ] = useGlobal();
  const { initialData } = globalState;
  const { changeStates } = globalActions;

  useEffect(() => {

    const getNew = async () => {

      const result = await backend.get( `news/${id}` );
      changeStates({ initialData: result });

    }

    getNew();

    return () => { changeStates({ initialData: {} }) };

  }, []);

  const handleEdit = ( form ) => {
    
    backend.edit( API.news.edit( id ), form, goBack );
  
  }

  const handleRemove = () => {
    
    if ( !window.confirm('Точно хотите удалить?') ) return;
    backend.remove( API.news.remove( id ), goBack, false );
  
  }

  return (

    <Carcas>

      <NewsForm
      
        initialData   = { initialData }
        handleConfirm = { handleEdit }
        handleRemove  = { handleRemove }
        handleCancel  = { goBack }
        
      />

    </Carcas>

  );

}

export default NewsEdit;