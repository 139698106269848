import { Link, useLocation} from "react-router-dom";
import cssIf from "../../../../scripts/helpers/class.add.if";
import "./navigation.scss";

const Navigation = () => {

  const location = useLocation();
  
  const MENU = [

    {

      title: "Пользователи",

      items: [

        // { text: "Администраторы", url: "/admins" },
        { text: "Партнёры (заявки)", url: "/partner-requests" },
        { text: "Партнёры", url: "/partners" },
        // { text: "Сотрудники", url: "/employees" },

      ],

    },

    {

      title: "Конфигуратор",

      items: [

        { text: "Корпуса", url: "/serverboxes" },
        { text: "Слоты", url: "/slots" },
        // { text: "Мультислоты", url: "/multislots" },
        { text: "Поколения серверов", url: "/server-generations" },
        { text: "Сервера", url: "/servers" },
        { text: "Поколения процессоров", url: "/cpu-generations" },
        { text: "Компоненты", url: "/components" },

      ],

    },

    {

      title: "Сделки",

      items: [

        { text: "Дистрибьюторы", url: "/distributors" },
        { text: "Все сделки", url: "/deals?all" },
        { text: "Запросы на регистрацию", url: "/deals?status=moderation" },
        { text: "Зарегистрированные", url: "/deals?status=registered" },
        { text: "Не зарегистрированные", url: "/deals?status=canceled" },
        { text: "Победные", url: "/deals?status=win" },
        { text: "Проигранные", url: "/deals?status=loose" },

      ],

    },

    {

      title: "Новости",

      items: [

        { text: "Все новости", url: "/news" },
        
      ],

    },

  ];

  const isActive = ( url ) => window.location.href.includes( url );

  return (

    <nav className = "menu">

      <div className = "logo">

        <Link to = "/"> Тринити</Link>

      </div> 

      { MENU.map(( section, sectionIndex ) => (

        <div 
        
          key = { sectionIndex } 
          className = "menu__section"
          
        > 

          <div className = "menu__title">

            { section.title }
            
          </div>

          { section.items.map(( item, key ) => (

            <NavElement

              key = { key }
              url = { item.url }
              text = { item.text }
              isActive = { isActive( item.url ) }
            
            />

          ))}

        </div>

      ))}

    </nav>

  );

}

const NavElement = ({ text, url, isActive = false }) => (

  <Link

    to = { url }
    className = {`menu__link ${ cssIf( isActive, "menu__link--active" ) }`}

  >

    { text }

  </Link>

);

export default Navigation;