import { Fragment } from "react";
import useObjectState from "../../../../scripts/hooks/use.object.state";
import Textfield from "../../../components/inputs/textfield";
import ActionButtons from "../../inputs/action.buttons";
import Textarea from "../../inputs/textarea";
import Filefield from "../../inputs/filefield";

const NewsForm = ( props ) => {

  const { 

    initialData, 
    handleConfirm, 
    handleCancel, 
    handleRemove = false,

  } = props;

  const [ form, setFormField ] = useObjectState( initialData );

  return (

    <Fragment>

      <h2>{ initialData?.url ? 'Редактировать' : 'Добавить' } новость</h2>
      
      <br/>

      <Textfield

        title = "Название статьи:"
        value = { form.name }
        set = { n => setFormField("name", n ) }

      />

      <Filefield
        
        title = "Обложка статьи:"
        value = { form.photo }
        set = { n => setFormField("photo", n ) }
        type = "image"
      
      />

      <Textarea

        title = "Текст статьи:"
        value = { form.content }
        set = { n => setFormField("content", n ) }

      />

      <ActionButtons

        confirm = { () => handleConfirm( form ) }
        remove = { handleRemove }
        cancel = { handleCancel }

      />
      
    </Fragment>

  );

}

export default NewsForm;