import { Fragment } from "react";
import useObjectState from "../../../../../scripts/hooks/use.object.state";
import Textfield from "../../../../components/inputs/textfield";
import ActionButtons from "../../../inputs/action.buttons";
import SelectFromBackend from "../../../inputs/select.from.backend";
import useIdFromState from "../../../../../scripts/hooks/use.id.from.state";

const SlotForm = ( props ) => {

  const { 

    initialData = { name: "" }, 
    handleConfirm, 
    handleCancel, 
    handleRemove = false 

  } = props;

  const [ form, setFormField ] = useObjectState( initialData );
  const [ componentType, setComponentType ] = useIdFromState( form?.type_id, id => setFormField("type_id", id ) );

  return (

    <Fragment>

      <h2>{ form?.id ? 'Редактировать' : 'Добавить' } слот</h2>
      
      <br/>

      <SelectFromBackend

        title = 'Тип слота:'
        source = 'configurator/componentType'
        selected = { componentType }
        select = { setComponentType }
        canBeNull

      />

      <Textfield

        title = "Название слота:"
        value = { form.name }
        set = { n => setFormField("name", n ) }

      />

      <ActionButtons

        confirm = { () => handleConfirm( form ) }
        remove = { handleRemove }
        cancel = { handleCancel }

      />
      
    </Fragment>

  );

}

export default SlotForm;