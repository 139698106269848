import twoDigitFormat from "./two.digit.format";

function UTCDate( value, getTime = true ) {

  let d = value;
  if (!d?.getDate) { d = new Date( value ); }

  let date = ``;

  date += `${ twoDigitFormat( d.getUTCDate() )}.`;
  date += `${ twoDigitFormat( d.getUTCMonth() + 1 ) }.`;
  date += d.getUTCFullYear();

  if ( getTime ) {

    date += ` ${ twoDigitFormat( d.getUTCHours() ) }`;
    date += `:${ twoDigitFormat( d.getUTCMinutes() ) }`;

  }

  return date;

}

export default UTCDate;