import { Fragment, useEffect, useMemo } from "react";
import useGlobal from "../../../../store";
import Selectfield from "../selectfield";
import backend from "../../../../scripts/backend";
import useReloadDummy from "../../../../scripts/hooks/use.reload.dummy";

const SelectFromBackend = ( props ) => {

  const {

    title = '',
    source = '',
    select = () => {},
    selected = false,
    canBeNull = false,
    placeholder = '',
    className = '',

  } = props;

  const [ globalState, globalActions ] = useGlobal();
  const { changeStates } = globalActions;

  const [ Dummy, reloadDummy ] = useReloadDummy();

  const isLoaded = useMemo(() => globalState?.[`${ source }_isLoaded`], [ globalState?.[`${ source }_isLoaded`] ]);

  async function load() {

    const data = await backend.get( source );

    changeStates({

      [ source ]: data || [],
      [`${ source }_isLoaded`]: true

    });

    if ( selected && !selected?.id && globalState?.[ source ]?.length ) {
      
      const selectedElementFullInfo = globalState?.[ source ].find( el => el.id === selected );
      selectedElementFullInfo && select( selectedElementFullInfo );

    }

    reloadDummy();

  }

  useEffect(() => { !isLoaded && load(); }, []);

  useEffect(() => {
    
    if ( selected?.id ) return;
    load();

  }, [ selected ]);

  return (

    <Fragment>

      <Dummy />

      <Selectfield

        title = { title }
        list = { globalState?.[ source ] || [] }
        select = { select }
        selected = { selected }
        isLoading = { !isLoaded }
        placeholder = { placeholder }
        className = { className }
        canBeNull = { canBeNull }

      />
      
    </Fragment>

  );

}

export default SelectFromBackend;