import { useEffect, useState } from "react";
import API from "../../../../constants/api";
import backend from "../../../../scripts/backend";
import "./filefield.scss";
import DeleteIcon from "./icons/delete";

const Filefield = ({ title = 'Загрузить файл:', value, set, className, type }) => {

  const [fileName, setFileName] = useState('');

  async function getValue() {

    const file = document.getElementById("file-upload").files[0];
    const response = await backend.postFormData( API.file.upload, file );
    const url = response?.configuration_link; 
    set(url);

  }

  useEffect(() => {

    if (value) {

      const parts = value.split("/");
      const name = parts[parts.length - 1];
      setFileName(name);

    }

  }, [value]);

  return (

    <div className = {`filefield ${ className }`}>

      <div className="filefield__title">{title}</div>

      { value 
      
        ? <div className="filefield__value">

            { type === 'image' && <img src = { value } alt = "" className="filefield__preview" /> }

            <div className="filefield__name">

              { type === 'document' 

                  ? <a href = { value } target = "_blank" rel = "noreferrer">{ fileName }</a>
                  : <span>{ fileName }</span>

              } 

              <DeleteIcon onClick={() => set(null)}/>

            </div>

          </div>

        : <label className="filefield__label">

            <span className="filefield__button">Выбрать</span>

            <input 
            
              type = "file" 
              className = "filefield__input"
              onChange = { () => getValue() }
              id = "file-upload"
              accept = { type === 'image' ? 'image/*' : type === 'document' ? '.doc, .docx, .pdf' : '' }

            />

          </label>
      
      }

    </div>

  );

};

export default Filefield;