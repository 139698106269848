const AvatarField = ({ children }) => {

  const content = (children?.includes('http') || !children?.length ) ? children : `https://${ children }`;
  
  return (
    <img src={ content } style = {{ width: '100%', height: 'auto' }} />
  )

}

export default AvatarField;