import Carcas from "../../../components/containers/carcas";
import backend from "../../../../scripts/backend";
import API from "../../../../constants/api";
import useGoBack from "../../../../scripts/hooks/use.go.back";
import { useParams } from "react-router-dom";
import useGlobal from "../../../../store";
import { useEffect } from "react";
import ServerboxForm from "../../../components/forms/configurator/serverbox.form";

const ServerboxEdit = () => {

  const params = useParams();
  const { id } = params;
  const goBack = useGoBack();

  const [ globalState, globalActions ] = useGlobal();
  const { initialData } = globalState;
  const { changeStates } = globalActions;

  useEffect(() => {
    
    !initialData?.id && goBack();
    return () => { changeStates({ initialData: {} }) }

  }, []);

  const handleEdit = ( form ) => {
    
    backend.edit( API.configurator.serverbox.edit( id ), form, goBack );
  
  }

  const handleRemove = () => {
    
    if ( !window.confirm('Точно хотите удалить?') ) return;
    backend.remove( API.configurator.serverbox.remove( id ), goBack );
  
  }

  return (

    <Carcas>

      <ServerboxForm
      
        initialData   = { initialData }
        handleConfirm = { handleEdit }
        handleRemove  = { handleRemove }
        handleCancel  = { goBack }
        
      />

    </Carcas>

  );

}

export default ServerboxEdit;