import { Fragment, useEffect, useMemo, useState } from "react";
import useObjectState from "../../../../../scripts/hooks/use.object.state";
import useIdFromState from "../../../../../scripts/hooks/use.id.from.state";
import SelectFromBackend from "../../../inputs/select.from.backend";
import Textfield from "../../../inputs/textfield";
import ActionButtons from "../../../inputs/action.buttons";
import RenderJSON from "../../../ui/testing/render.json";
import SlotsContainer from "../../../containers/slots.container";
import useArrayOfObjects from "../../../../../scripts/hooks/use.array.of.objects";
import ComponentSlot from "../../../cards/component.slot";
import isNotEmpty from "../../../../../scripts/validators/is.not.empty";
import allObjectsIsHaveFilledKey from "../../../../../scripts/validators/all.objects.is.have.filled.key";
import Selectfield from "../../../inputs/selectfield";
import { DISK_SUBTYPES } from "../../../../../constants/subtypes";
import { COMPONENT_TYPE } from "../../../../../constants/db.ids";

const ComponentForm = ({ initialData, handleConfirm, handleCancel, handleRemove = false  }) => {

  const [ isDataLoaded, setDataLoaded ] = useState( false );

  const [ form, setFormField ] = useObjectState( initialData );
  const [ componentType, setComponentType ] = useIdFromState( form?.type_id, id => setFormField("type_id", id ) );

  const isDisk = form?.type_id === COMPONENT_TYPE.MEMORY;
  const isCPU  = form?.type_id === COMPONENT_TYPE.CPU;
  const isRAM  = form?.type_id === COMPONENT_TYPE.RAM;

  const initialSlotData = {
      
    slot_id: null,
    amount: 1,
    increase: false

  }

  const slot = useArrayOfObjects( form?.slots, payload => setFormField( "slots", payload ), initialSlotData );

  const [ 

    componentSubType, 
    setComponentSubType, 
    clearComponentSubType 

  ] = useIdFromState( form?.subtype, id => setFormField("subtype", id ) );

  const [ 
    
    serverGeneration, 
    setServerGeneration, 
    clearServerGeneration 
  
  ] = useIdFromState( form?.server_generation_id, id => setFormField("server_generation_id", id ) );

  const [ 
    
    processorGeneration,
    setProcessorGeneration, 
    clearProcessorGeneration 
  
  ] = useIdFromState( form?.processor_generation_id, id => setFormField("processor_generation_id", id ) );
  
  const formIsValidated = useMemo(() => (
    
    isNotEmpty( form?.type_id )
    &&
    isNotEmpty( form?.name )
    &&
    isNotEmpty( form?.price )
    &&
    isNotEmpty( form?.slots )
    &&
    allObjectsIsHaveFilledKey( form?.slots, 'slot_id' )

  ), [ form?.serverbox_height_id, form?.name, form?.price, slot._trigger ]);

  useEffect(() => { 

    setDataLoaded(true);
    return () => setDataLoaded(false);

  }, []);

  useEffect(() => { 
    
    clearComponentSubType(); 

    if ( isDataLoaded ) {

      clearServerGeneration();
      clearProcessorGeneration();

    }

  }, [ form?.type_id ]);

  useEffect(() => {

    if ( !form?.subtype ) return;

    isDisk && setComponentSubType( DISK_SUBTYPES.find( el => el.id === form?.subtype ) );

  }, [ form?.subtype ]);

  return (

    <Fragment>

      <h2>{ form?.id ? 'Редактировать' : 'Добавить' } компонент</h2>
      
      <br/>

      <SelectFromBackend

        title = 'Тип компонента'
        source = 'configurator/componentType'
        selected = { componentType }
        select = { setComponentType }

      />

      { isCPU &&

        <Fragment>
          
          <SelectFromBackend

            title = 'Поколение процессора:'
            source = 'configurator/processorGeneration'
            selected = { processorGeneration }
            select = { setProcessorGeneration }

          />
          
          <SelectFromBackend

            title = 'Совместимое поколение сервера:'
            source = 'configurator/serverGeneration'
            selected = { serverGeneration }
            select = { setServerGeneration }

          />

        </Fragment>
      
      }

      { isRAM && 
          
        <SelectFromBackend

          title = 'Совместимое поколение процессора:'
          source = 'configurator/processorGeneration'
          selected = { processorGeneration }
          select = { setProcessorGeneration }

        />
        
      }

      { isDisk && 
      
        <Selectfield

          title = 'Тип диска (в названии не отображается):'
          list = { DISK_SUBTYPES }
          selected = { componentSubType }
          select = { setComponentSubType }

        />
        
      }

      <Textfield

        title = "Название компонента:"
        value = { form.name }
        set = { n => setFormField("name", n ) }

      />

      <Textfield

        title = "Стоимость:"
        type = "number"
        value = { form.price }
        set = { n => setFormField("price", +n ) }

      />

      <SlotsContainer add = { slot.add }>

        { !isNotEmpty( form?.slots ) ? null : form?.slots.map(( s, index ) => (

          <ComponentSlot

            key = { index }
            slotId = { s.slot_id }
            amount = { s.amount }
            increase = { s.increase }

            set = { ( field, value ) => slot.edit( index, field, value ) }
            remove = { () => slot.remove( index ) }

          />

        ))}

      </SlotsContainer>

      {/* <RenderJSON data = { form?.slots } /> */}

      <ActionButtons

        confirm = { () => handleConfirm( form ) }
        confirmDisabled = { !formIsValidated }

        remove = { handleRemove }
        cancel = { handleCancel }

      />

    </Fragment>

  );

}

export default ComponentForm;