const months = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
]

const DateFieldWithoutTime = ({ children }) => {

  const d = new Date( children )
  if ( !d?.getDate() ) { return <span>Некорректная дата</span> }

  const date = `${ d.getUTCDate() } ${ months[ d.getMonth() ] } ${ d.getFullYear() }`;
  
  return (

    <span>{ date }</span>

  );

}

export default DateFieldWithoutTime;
